exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-attributions-js": () => import("./../../../src/pages/attributions.js" /* webpackChunkName: "component---src-pages-attributions-js" */),
  "component---src-pages-christmas-opening-times-js": () => import("./../../../src/pages/christmas-opening-times.js" /* webpackChunkName: "component---src-pages-christmas-opening-times-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-diving-js": () => import("./../../../src/pages/diving.js" /* webpackChunkName: "component---src-pages-diving-js" */),
  "component---src-pages-facilities-js": () => import("./../../../src/pages/facilities.js" /* webpackChunkName: "component---src-pages-facilities-js" */),
  "component---src-pages-gear-js": () => import("./../../../src/pages/gear.js" /* webpackChunkName: "component---src-pages-gear-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-opening-times-js": () => import("./../../../src/pages/opening-times.js" /* webpackChunkName: "component---src-pages-opening-times-js" */),
  "component---src-pages-photo-gallery-js": () => import("./../../../src/pages/photo-gallery.js" /* webpackChunkName: "component---src-pages-photo-gallery-js" */),
  "component---src-pages-staff-js": () => import("./../../../src/pages/staff.js" /* webpackChunkName: "component---src-pages-staff-js" */),
  "component---src-pages-swimming-js": () => import("./../../../src/pages/swimming.js" /* webpackChunkName: "component---src-pages-swimming-js" */),
  "component---src-pages-video-gallery-js": () => import("./../../../src/pages/video-gallery.js" /* webpackChunkName: "component---src-pages-video-gallery-js" */)
}

